import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
export default defineComponent({
    name: "Loader",
    props: {
        logo: String,
    },
    setup: function () {
        var store = useStore();
        var loaderType = computed(function () {
            return store.getters.layoutConfig("loader.type");
        });
        return {
            loaderType: loaderType,
        };
    },
});
